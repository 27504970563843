// Styles
import 'fonts/fonts.scss'
import 'normalize/normalize.scss'
import 'slick/slick.scss'
import 'styles/styles.scss'
import 'common/res/css/BoldChat.css'

// Scripts
import 'scripts/scripts'
import 'slick'
