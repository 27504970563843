/* --------------------------------------------------------------------------- */
$('.header__mobile-menu').click(function() {
    showOverlay()
    showMainMenu()
})
/* --------------------------------------------------------------------------- */
$('.header__mobile-menu--close-btn').click(function() {
    hideOverlay()
    hideMainMenu()
})
/* --------------------------------------------------------------------------- */
function showOverlay() {
    $('.overlay').fadeIn(200)
    $('html, body').addClass('fixed')
}
function hideOverlay() {
    $('.overlay').fadeOut(200)
    $('html, body').removeClass('fixed')
}
function showMainMenu() {
    $('.body__wrapper').addClass('main-menu-popup')
}
function hideMainMenu() {
    $('.body__wrapper').removeClass('main-menu-popup')
}
function showCustomerMainMenu() {
    if ($('.cutomer-menu').length) {
        $('.body__wrapper').addClass('customer-popup')
    } else {
        $('.body__wrapper').addClass('login-popup')
    }
}
function hideCustomerMainMenu() {
    if ($('.cutomer-menu').length) {
        $('.body__wrapper').removeClass('customer-popup')
    } else {
        $('.body__wrapper').removeClass('login-popup')
    }
}
/* --------------------------------------------------------------------------- */
$('.header__login--text').click(function() {
    $('.loginform').toggle()
})
/* --------------------------------------------------------------------------- */
$('.header__mobile-login-popup').click(function() {
    showCustomerMainMenu()
    showOverlay()
})
/* --------------------------------------------------------------------------- */
$('.header__mobile-login-popup--close').click(function() {
    hideCustomerMainMenu()
    hideOverlay()
})
/* --------------------------------------------------------------------------- */
$('body').click(function(e) {
    $('.loginform').hide()
})
$('.loginform, .header__login--text').click(function(e) {
    e.stopPropagation()
})
/* --------------------------------------------------------------------------- */
$('.prices-qualitytable td').bind('touchend', function(e) {
    if ($(this).hasClass('hover')) {
        $(this).removeClass('hover').closest('tr').removeClass('active')
    } else {
        e.preventDefault()
        $('.prices-qualitytable td').removeClass('hover').closest('tr').removeClass('active')
        $(this).addClass('hover').closest('tr').addClass('active')
    }
})

$('.prices-qualitytable td').hover(function() {
    $(this).addClass('hover').closest('tr').addClass('active')
}, function() {
    $(this).removeClass('hover').closest('tr').removeClass('active')
})
/* --------------------------------------------------------------------------- */
var loc = window.location.pathname
$('.header__menu-item').each(function() {
    $(this).toggleClass('active', $(this).attr('href') == loc)
})
/* --------------------------------------------------------------------------- */
$('.discount-info__graph').bind('touchend', function(e) {
    e.preventDefault()
    if ($(this).hasClass('hover_effect')) {
        $(this).removeClass('hover_effect')
    } else {
        $(this).addClass('hover_effect')
    }
})

$('.discount-info__graph').hover(function() {
    $(this).addClass('hover_effect')
}, function() {
    $(this).removeClass('hover_effect')
})
/* --------------------------------------------------------------------------- */
function hintsHover() {
    $('.orderform__hint').bind('touchend', function(e) {
        e.preventDefault()
        if ($(this).hasClass('hover_effect')) {
            $(this).removeClass('hover_effect')
        } else {
            $('.orderform__hint').removeClass('hover_effect')
            $(this).addClass('hover_effect')
        }
    })

    $('.orderform__hint').hover(function() {
        $(this).addClass('hover_effect')
    }, function() {
        $(this).removeClass('hover_effect')
    })
}
hintsHover()
/* --------------------------------------------------------------------------- */

$('.link_txt_popap').click(function() {
    $('.txt_popap__wrapper').show()
    return false
})

$('.txt_popap__close').click(function() {
    $('.txt_popap__wrapper').hide()
    return false
})
