import 'slick-carousel/slick/slick.js'

$('.testiminials__wrapper').slick({
    adaptiveHeight: true,
    dots: true,
    slidesToShow: 3,
    variableWidth: true,
    speed: 200,
    responsive: [
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                variableWidth: false
            }
        }
    ]
})

$('.sidebar__testimonials-wrapper').slick({
    dots: true,
    slidesToShow: 2,
    variableWidth: false,
    speed: 200,
    vertical: true,
    arrows: false,
    slidesToScroll: 2
})
